// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'

export const NominateBusiness = gql`
  mutation nominateBusiness($payload: String!) {
    nominateBusiness(payload: $payload)
  }
`
export interface INominateBusinessData {
  nominateBusiness: string
}

export default NominateBusiness
