import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Content from '../../../components/Content/Content'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import AwardsHeader from '../../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../../components/MuddyAwards/AwardsMenu'
import NominateWidget from '../../../components/MuddyAwards/NominateWidget'
import Breadcrumb from '../../../components/organisms/breadcrumb/Breadcrumb'
import VideoPageMeta from '../../../components/PageMeta/VideoPageMeta'
import React, { useState, useEffect, useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { IMenu } from 'graphql/entities/Menu'
import {
  GeneralSettings,
  AdStarBlock,
  Category,
  SponsoredCategories
} from 'types'
import CTARow from '../../../components/CTA/CTARow'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../../lib/utils'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
        finalists {
          meta_a
          meta_b
          meta_c
          meta_d
          meta_e
        }
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface NominateProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
      awardsCategories: {
        id: string
        title: string
        image: string
        description: string
        finalists: {
          meta_a: string
          meta_b: string
          meta_c: string
          meta_d: string
          meta_e: string
        }
      }[]
    }
  }
}

const Nominate = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      awardsCategories,
      category
    }
  }
}: NominateProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  return (
    <>
      <VideoPageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'I nominated my favourite business in the Muddy Awards 2024!',
          image:
            'http://dev.muddystilettos.co.uk/wp-content/uploads/2024/02/social-pull.jpeg'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Nominate'}
            postURI={'/awards/nominate'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'Nominate' }} />
          <p>
            Still deciding what category to nominate in? Take a look at our 21
            lifestyle categories <Link to={`/awards/categories`}>here</Link> and
            put forward your favourite local business.
          </p>
        </Content>
        {AwardsContext !== MuddyAwardsStates.NOMINATIONS ? (
          <Content>
            <h1>NOMINATIONS ARE NOW CLOSED</h1>
            <p>
              Finalists have been announced and voting is now open. Click{' '}
              <Link to="/awards/vote">here</Link> to cast your vote.
            </p>
          </Content>
        ) : (
          <NominateWidget
            siteUrl={generalSettings.url ?? ''}
            siteName={generalSettings.title ?? 'your County'}
            categories={awardsCategories}
          />
        )}
      </div>
      <CTARow order={4}>
        <h3>Get Muddy</h3>
        <p>
          Sign up for our inside line on the most brilliant things happening
          near you
        </p>
        <p>
          <Link to="/sign-up">Sign up for our free newsletter</Link>
        </p>
      </CTARow>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default Nominate
