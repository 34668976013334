import * as NominateStyles from './NominateWidget.module.scss'
import React, { useContext, useEffect, useState, useReducer } from 'react'
import SocialShare from './SocialShare'
import {
  LoginContext,
  MuddyAwardsContext,
  MuddyAwardsStates,
  hashEmail,
  unhashEmail
} from '../../../lib/utils'
import {
  NominateBusiness,
  type INominateBusinessData
} from '../../../graphql/queries/NominateQuery'
import { useMutation } from '@apollo/client'
import Hr from '../../../components/Hr/Hr'
import { StaticImage } from 'gatsby-plugin-image'
import SpamDetector from '../SpamDetector'
import { Link } from 'gatsby'

interface AwardCategory {
  id: string
  title: string
  image: string
  description: string
}

interface NominateWidgetProps {
  categories: AwardCategory[]
  siteName: string
  siteUrl: string
}

interface ReducerEvent {
  name: string
  value: string
}

interface ReducerState {
  businessName: string
  businessLocation: string
  emailAddress?: string
  uniqueLinkEmail?: string
  cat?: string
  spamdetect?: string
}

const NominateWidget: React.FC<NominateWidgetProps> = ({
  categories,
  siteUrl,
  siteName
}) => {
  const { usr } = useContext(LoginContext)
  const AwardsContext = useContext(MuddyAwardsContext)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [uniqueId, setUniqueId] = useState<string | undefined>()
  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? location.search : undefined
  )
  const category = searchParams.get('c')
  const uid = searchParams.get('uid')

  const [selectedCategory, setSelectedCategory] = useState<string>(
    category ?? ''
  )
  const [formValid, setFormValid] = useState<boolean>(false)
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false)
  const [showChangeCategory, setShowChangeCategory] = useState<boolean>(true)
  const [selectedCategoryObject, setSelectedCategoryObject] = useState<
    AwardCategory | undefined
  >()
  const [requestingUniqueLink, setRequestingUniqueLink] =
    useState<boolean>(false)
  const [uniqueLinkSent, setUniqueLinkSent] = useState<boolean | Error>(false)

  const [sendingNomination, setSendingNomination] = useState<boolean>(false)
  const [nominationSent, setNominationSent] = useState<boolean>(false)
  const [nominationError, setNominationError] = useState<string | undefined>()

  const formReducer = (state: ReducerState, event: ReducerEvent) => {
    return {
      ...state,
      [event.name]: event.value
    }
  }

  const InitialState: ReducerState = Object({
    businessName: '',
    businessLocation: '',
    siteName: siteName
  })

  const [formData, setFormData] = useReducer(formReducer, InitialState)

  const [nominateBusiness] = useMutation<INominateBusinessData>(
    NominateBusiness,
    {
      variables: { payload: JSON.stringify(formData) }
    }
  )

  useEffect(() => {
    if (usr && usr.viewer?.hasOwnProperty('name')) {
      setFormData({
        name: 'emailAddress',
        value: usr.viewer.email ?? ''
      })
      setLoggedIn(true)
    }
  }, [usr])

  useEffect(() => {
    if (uid !== null) {
      setUniqueId(uid)
    }
  }, [uid])

  useEffect(() => {
    if (selectedCategory.length > 0) {
      const cat = categories.filter(c => c.id === selectedCategory)
      setSelectedCategoryObject(cat.length > 0 ? cat[0] : undefined)
      setShowChangeCategory(false)
      setFormData({
        name: 'cat',
        value: selectedCategory
      })
    }
  }, [selectedCategory])

  const createUniqueLink = async () => {
    if (formData.uniqueLinkEmail) {
      const hashedEmail = encodeURIComponent(
        hashEmail(formData.uniqueLinkEmail)
      )
      const uniqueLink = `/awards/nominate/?uid=${hashedEmail}`

      try {
        const reqBody = new FormData()
        reqBody.append('link', uniqueLink)
        reqBody.append('email', formData.uniqueLinkEmail ?? '')
        setRequestingUniqueLink(true)
        const requestLink = await fetch(
          `${siteUrl}/wp-json/muddy/create-voting-link`,
          {
            method: 'post',
            body: reqBody
          }
        )
          .then(response => {
            return response.json()
          })
          .catch(e => {
            return e.message
          })

        if (requestLink.data === 'success') {
          setUniqueLinkSent(true)
        } else {
          throw new Error(
            'It seems as though the email address was invalid. Could you try again?'
          )
        }
      } catch (e: any) {
        setUniqueLinkSent(e)
      } finally {
        setRequestingUniqueLink(false)
      }
    }
  }

  const sendToDatabase = async () => {
    try {
      const { data } = await nominateBusiness()
      if (data?.nominateBusiness) {
        const res = JSON.parse(data?.nominateBusiness)
        if (res.success === 0) {
          throw new Error(res.message)
        }
      }
    } catch (e: any) {
      if (e instanceof Error) {
        if (e.message === 'alreadyVoted') {
          setNominationError(
            'Sorry, it appears that you’ve already voted in this category. Please select a different category'
          )
        } else if (e.message === 'unspecifiedError') {
          setNominationError(
            'Sorry, there was an unspecified error whilst trying to submit your nomination. Please try again by clicking'
          )
        } else if (e.message === 'invalidEmail') {
          setNominationError(
            'Sorry, there appears to be a problem with the email address supplied. Please try again by clicking'
          )
        } else if (e.message === 'closed') {
          setNominationError(
            'Sorry, nominations are currently closed. Check back in soon for the finalists.'
          )
        }
      } else {
        // Most likely means mysql is down.
        setNominationError('Sorry, there was an error submitting your details.')
      }
    } finally {
      setSendingNomination(false)
      setNominationSent(true)
    }
  }

  const handleNomination = () => {
    if (!loggedIn && formData.emailAddress && uniqueId) {
      const uniqueEmail = unhashEmail(uniqueId)
      if (formData.emailAddress.toLowerCase() !== uniqueEmail) {
        setEmailInvalid(true)
      } else {
        setSendingNomination(true)
        sendToDatabase()
      }
    } else if (loggedIn) {
      setSendingNomination(true)
      sendToDatabase()
    }
  }

  const validateForm = () => {
    const errors = []
    if (!loggedIn) {
      if (!formData.emailAddress || formData.emailAddress.length < 1) {
        errors.push('email address')
      }
    }

    if (formData.businessName.length < 1) {
      errors.push('business name')
    }

    if (formData.businessLocation.length < 1) {
      errors.push('business location')
    }

    if (!formData.spamdetect || formData.spamdetect.length < 1) {
      errors.push('spam detect')
    }

    if (!formData.cat || formData.cat.length < 1) {
      errors.push('category')
    }

    setFormValid(errors.length < 1)
  }

  const handleFieldChange = (field: any) => {
    setFormData({
      name: field.target.name,
      value: field.target.value
    })
  }

  useEffect(() => {
    validateForm()
  }, [formData])

  if (AwardsContext === MuddyAwardsStates.NOMINATIONS_CLOSED) {
    return (
      <div className={NominateStyles.Wrapper}>
        <div className={NominateStyles.Nominate}>
          <h2 className={NominateStyles.Boomshell}>Sorry!</h2>
          <p>Nominations are now closed.</p>
          <p>
            Check back shortly for more information about voting for the
            finalists!
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={NominateStyles.Wrapper}>
      {!loggedIn && !uniqueId ? (
        <div className={NominateStyles.CreateUniqueLink}>
          {uniqueLinkSent ? (
            <>
              {uniqueLinkSent instanceof Error ? (
                <>
                  <h2>Oh no!</h2>
                  <p>{uniqueLinkSent.message}</p>
                </>
              ) : (
                <>
                  <h2>Thanks!</h2>
                  <p>
                    Your nomination link has been sent. Please check your inbox
                    or junk folder and click the email link to nominate all
                    categories. If you want to nominate in further categories on
                    subsequent days, <u>you must re-use the same link</u>.
                  </p>
                  <p>
                    Click <Link to={`/awards/about-us/`}>here</Link> to find out
                    more about Muddy Stilettos and our award-winning lifestyle
                    site.
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              {!requestingUniqueLink ? (
                <>
                  <h2>
                    Enter your email address to nominate your favourite
                    business.
                  </h2>
                  <input
                    name="uniqueLinkEmail"
                    defaultValue=""
                    placeholder="Enter your email address"
                    onChange={e => handleFieldChange(e)}
                    type="text"
                  />
                  <button
                    onClick={createUniqueLink}
                    disabled={!formData.uniqueLinkEmail}
                    className={NominateStyles.Button}
                  >
                    Get your unique nomination link
                  </button>
                </>
              ) : (
                <>
                  <p>Requesting unique link from the server.</p>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={NominateStyles.Nominate}>
          {nominationSent ? (
            <>
              {!nominationError ? (
                <>
                  <h2 className={NominateStyles.Boomshell}>Thank you!</h2>
                  <p>Your nomination has been registered.</p>
                  <p>
                    To nominate another business in a different category, click
                    {` `}
                    <span
                      style={{
                        color: '#d41a50',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }}
                      onClick={() => {
                        setNominationSent(false)
                        setShowChangeCategory(true)
                        setNominationError(undefined)
                      }}
                    >
                      here.
                    </span>
                  </p>
                  <Hr />
                  <div className={NominateStyles.StepThree}>
                    <h3>What&apos;s next?</h3>
                    <p className={NominateStyles.Step}>
                      <span className={NominateStyles.Number}>1.</span>SHARE ON
                      SOCIAL
                    </p>
                    <SocialShare siteUrl={siteUrl} />
                    <div className={NominateStyles.Spacer} />

                    <p className={NominateStyles.Step}>
                      <span className={NominateStyles.Number}>2.</span>WIN a
                      two-night stay at THE PIG hotel of your choice, worth
                      £1,250.
                    </p>
                    <StaticImage
                      src={`../../../../static/images/awards/rt2024.jpg`}
                      width={400}
                      alt={
                        '£1250 TWO NIGHT STAY AT THE PIG HOTEL OF YOUR CHOICE'
                      }
                    />
                    <p>
                      Because if you’ve nominated and shared, your good karma
                      needs rewarding.
                    </p>
                    <a
                      href={
                        'https://muddystilettos.co.uk/reader-treats/win-two-night-stay-the-pig-hotel-of-your-choice/'
                      }
                      className={NominateStyles.Button}
                    >
                      Click & win
                    </a>

                    <div className={NominateStyles.Spacer} />
                    <p className={NominateStyles.Step}>
                      <span className={NominateStyles.Number}>3.</span>Relax!
                      Time to check out your{' '}
                      <Link to={`/`}>local Muddy website</Link> for the inside
                      line on what’s happening near you – from new pubs and
                      boutiques to the best of what’s on – and sign up to our{' '}
                      <Link to={'/sign-up/'}>free newsletter</Link> to stay
                      in-the-know.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <h2>Oh no!</h2>
                  <p>
                    {nominationError}&nbsp;
                    <span
                      style={{
                        color: '#d41a50',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }}
                      onClick={() => {
                        setNominationSent(false)
                        setShowChangeCategory(true)
                        setNominationError(undefined)
                      }}
                    >
                      here.
                    </span>
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              {sendingNomination ? (
                <>
                  <p>Please wait whilst we send your nomination!</p>
                </>
              ) : (
                <>
                  <h2>
                    Nominate your favourite{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          selectedCategoryObject !== undefined
                            ? selectedCategoryObject.title.replace('Best ', '')
                            : 'local business'
                      }}
                    />{' '}
                    in {siteName.replace('&amp;', '&')}
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedCategoryObject !== undefined
                          ? selectedCategoryObject.description
                          : 'Choose a category from the list below to get started!'
                    }}
                  />

                  {(!selectedCategoryObject || showChangeCategory) && (
                    <select
                      name="cat"
                      defaultValue={selectedCategory ?? ''}
                      onChange={e => setSelectedCategory(e.target.value)}
                    >
                      <option disabled value="">
                        Choose a category
                      </option>
                      {categories.map(c => (
                        <option
                          value={c.id}
                          key={c.id}
                          dangerouslySetInnerHTML={{ __html: c.title }}
                        />
                      ))}
                    </select>
                  )}

                  {!showChangeCategory && (
                    <button
                      className={NominateStyles.ChangeCategoryButton}
                      onClick={() => setShowChangeCategory(true)}
                    >
                      Change Category
                    </button>
                  )}

                  {!loggedIn && (
                    <>
                      <input
                        name="emailAddress"
                        defaultValue=""
                        className={`${
                          emailInvalid ? NominateStyles.Invalid : ''
                        }`}
                        placeholder="Enter your email address"
                        onChange={e => handleFieldChange(e)}
                        type="text"
                      />
                      {emailInvalid && (
                        <span>
                          This email address doesn&apos;t match the Unique ID.
                        </span>
                      )}
                    </>
                  )}

                  <input
                    name="businessName"
                    defaultValue=""
                    placeholder="Enter your nominated business"
                    onChange={e => handleFieldChange(e)}
                    type="text"
                  />

                  <input
                    name="businessLocation"
                    defaultValue=""
                    placeholder="Enter its business location"
                    onChange={e => handleFieldChange(e)}
                    type="text"
                  />

                  <SpamDetector setFormData={setFormData} />

                  <button
                    disabled={!formValid}
                    onClick={handleNomination}
                    className={NominateStyles.Button}
                  >
                    Nominate
                  </button>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default NominateWidget
